import React from 'react';
import './Clientreview.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import banner_img from '../../assets/Images/client_review_img.webp';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';
import { motion } from 'framer-motion'

const Clienreview = () => {

    const clientReviews = [
        {
            clientName : 'Sathya Seelan',
            clientReview : 'The company just provided me a new look of my flat which was more than what i really expected....I would recommend my colleagues who are  looking for the best interior designer....good team work and services are very satisfactory....!!'
        },
        {
            clientName : 'amsyednazar',
            clientReview : 'Amazing  work  from start to finish. They took the time we wanted up front to design and  the product reflected that. They have insightful advice and did a great job of really listening to what we wanted.'
        },
        {
            clientName : 'TAMIL R',
            clientReview : 'Great initiative...Buid our dream home with them, Render images of their project shocked me when i saw the image with after execution, both are picture perfect. Interior and exterior works as match with my soulful thoughts exactly. They work like build their own kingdom in every single project. I am happy with share my experience with saha space that speaks...'
        },
    ]

    const trans1 = {duration: 0.6}

  return (
    <div className="container clientreviews">
        <div className='d-lg-grid'>
            <div className='row row-gap-4'>
                <div className="client_review_img col-lg-6 col-md-12 d-flex justify-content-center">
                    <img src={banner_img} alt="" className='img-fluid'/>
                </div>
                <div className="clientreviews-carousel col-lg-6 col-md-12 align-items-center text-center">
                <motion.div
                initial={{transform:'translateY(5rem)',opacity:'0'}}
                whileInView={{transform:'translateY(0rem)',opacity:'1'}}
                transition={trans1}
                viewport={{once:true}}
                >
                    <span className='clientreviews-carousel-title fs-3'>What Our Clients say about us</span>
                </motion.div>
                    <Carousel
                    className='carousel position-relative'
                        showThumbs={false} 
                        showStatus={false}
                        infiniteLoop={true}
                        centerMode={false}
                        showArrows={true} 
                        autoPlay={true}
                        showIndicators={false}
                        interval={5000}
                        preventMovementUntilSwipeScrollTolerance={true}
                        swipeScrollTolerance={50}
                        dynamicHeight={true}
                          renderArrowNext={(clickHandler) => (
                              <div
                                className='position-absolute top-0 bottom-0 end-0 d-flex justify-content-center align-items-center'
                                onClick={clickHandler}
                              >
                                <FaCaretRight className="fs-5 text-white" />
                              </div>
                            )}
                          renderArrowPrev={(clickHandler) => (
                              <div
                                className='position-absolute start-0 bottom-0 top-0 d-flex align-items-center justify-content-center z-1'
                                // style={{zIndex:'1'}}
                                onClick={clickHandler}
                              >
                                <FaCaretLeft className="fs-5 text-white" />
                              </div>
                            )}
                    >
                        {
                            clientReviews.map((review,index)=>(
                                <div className='client_carousel px-4' key={index}>
                                    <div className='carousel-slide-text-container d-flex flex-column text-wrap'>
                                        <em className='py-4 client-review'>{review.clientReview}</em>
                                        <div className='d-flex flex-column'>
                                            <span className='fw-bold'>{review.clientName}</span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
            </div>
        </div>

    </div>
    
    
  );
};

export default Clienreview;