import React, { useEffect } from 'react'
import './Home.css'
import Banner from '../src/Component/Banner/Banner'
import Footer from '../src/Component/Footer/Footer'
import About from '../src/Component/About/Aboutus'
import Service from './Component/Service/Service'
import Contact from './Component/Contact/Contact'
import Ourservices from './Component/Our_services/Ourservices'
import Clientreview from './Component/ClientReview/Clientreview'
import Googlemap from './Component/GoogleMap/Googlemap'
import Clientele from './Component/Clientele/Clientele'
// import Identity from './Component/Identity/Identity'

function Home() {
  useEffect(()=>{
    window.scrollTo(0,0)
  })
  return (
    <div className='bg-black text-white'>
      <Banner />
      <About />
      <Service />
      <Ourservices/>
      <Clientele/>
      <Clientreview/>
      <Contact />
      <Footer />
      <Googlemap/>
    </div>
    
  )
}
export default Home;