import React from 'react'
import './Clientele.css'
import { motion } from 'framer-motion'
import Clientele_img1 from '../../assets/Images/clientele_img1.webp'
import Clientele_img2 from '../../assets/Images/Clientele_img2.webp'
import Clientele_img3 from '../../assets/Images/clientele_img3.webp'
import Clientele_img4 from '../../assets/Images/clientele_img4.webp'
import Clientele_img5 from '../../assets/Images/clientele_img5.webp'

const Clientele = () => {
    const trans1 = {duration:0.3}
    const images = [
        Clientele_img1,
        Clientele_img2,
        Clientele_img3,
        Clientele_img4,
        Clientele_img5,
    ]
  return (
    <>
    <div className='my-5 container'>
        <div className='text-center h1 pb-5'>
            <motion.div
            initial={{transform: 'translateY(2rem)',opacity: '0'}}
            whileInView={{transform: 'translateY(0rem)',opacity: '1'}}
            transition={trans1}
            viewport={{once:true}}
            >
                <span className='clientele-title'>Our Clients</span>
            </motion.div>
        </div>
            <div className='d-flex flex-sm-column flex-column flex-md-row justify-content-center align-items-center align-items-md-none gap-3'>
                {/* <div className='row'> */}
                    {
                        images.map((img,index)=>(
                            <div className='clientele_img_div'>
                                <img src={img} key={index} className=''/>
                            </div>
                        ))
                    }
                </div>
            {/* </div> */}
    </div>
    </>
  )
}

export default Clientele

// d-flex flex-sm-row flex-column align-items-center align-items-sm-none  justify-content-sm-center
// clientele_img_div
// pe-sm-3 pb-3 pb-sm-0