import React from 'react'
import './Ourservices.css'
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import HouseSidingOutlinedIcon from '@mui/icons-material/HouseSidingOutlined';
import LandscapeOutlinedIcon from '@mui/icons-material/LandscapeOutlined';
import { motion } from "framer-motion"

const Ourservices = () => {
    const trans1 = {duration:0.3}
    const trans2 = {duration:0.6}
    const trans3 = {duration:0.9}
  return (
    <div className='container-fluid px-4 my-5'>
        <div className='h2 text-center fw-light'>
            <motion.div
            initial={{transform: 'translateY(5rem)'}}
            whileInView={{transform: 'translateY(0rem)'}}
            transition={trans2}
            viewport={{once:true}}
            >
                <span>Our Services</span>
            </motion.div>
        </div>
        <div className='ourservices d-grid container py-5'>
            <div className='row row-gap-5'>
                {/* <div className='d-flex flex-column align-items-center col-lg-4 col-sm-6'>
                    <FoundationOutlinedIcon className='fs-1'/>
                    <span className='py-4 h4'>Architecture</span>
                    <span className='lh-lg text-secondary'>We believe God is in the details . We work hard to give a character to the space by providing decor items, furniture, flooring, wall painting and other decorative elements.</span>
                </div> */}
                <div className='col-lg-4 col-sm-6'>
                    <motion.div
                    initial={{translateY:"10rem",opacity:'0'}}
                    whileInView={{translateY:"0rem",opacity:'1'}}
                    viewport={{once:true}}
                    // whileHover={{ scale: 1.1 }}
                    transition={trans1}
                    >
                        <div className='ourservices-div d-flex flex-column align-items-center'>
                            <HouseSidingOutlinedIcon className='ourservices-icon' style={{fontSize:"60px"}}/>
                            <span className='ourservices-title py-4 h4 fw-bold'>Construction</span>
                            <span className='ourservices-content lh-lg'>We deliver quality structures at a fair price and on time that best fits our client needs.</span>
                        </div>
                    </motion.div>
                </div>
                <div className='col-lg-4 col-sm-6'>
                    <motion.div
                    initial={{translateY:"10rem"}}
                    whileInView={{translateY:"0rem"}}
                    viewport={{once:true}}
                    transition={trans2}
                    >
                        <div className='ourservices-div d-flex flex-column align-items-center'>
                            <MeetingRoomOutlinedIcon className='ourservices-icon' style={{fontSize:"60px"}}/>
                            <span className='ourservices-title py-4 h4 fw-bold'>Interior</span>
                            <span className='ourservices-content lh-lg'>Sculpting every nooks and corners with attention by providing sufficient details to get the best out of the final product.</span>
                        </div>
                    </motion.div>
                </div>
                <div className='col-lg-4 col-sm-6'>
                    <motion.div
                    initial={{translateY:"10rem"}}
                    whileInView={{translateY:"0rem"}}
                    viewport={{once:true}}
                    // whileHover={{ scale: 1.1 }}
                    transition={trans3}
                    >
                        <div className='ourservices-div d-flex flex-column align-items-center'>
                            <LandscapeOutlinedIcon className='ourservices-icon' style={{fontSize:"60px"}}/>
                            <span className='ourservices-title py-4 h4 fw-bold'>Landscape</span>
                            <span className='ourservices-content lh-lg'>We retain your garden green with our landscaping ideas to enhance the environment.</span>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Ourservices