import React from 'react'
import './Identity.css'

const Identity = () => {
  return (
    <>
    <div className='identity-container d-flex flex-column text-center container py-5'>
        <div className='identity-first-div d-flex flex-column lh-lg'>
            <i className='identity-title'>Who <span className='fw-medium fst-normal'>We Are</span></i>
            <span className='identify-content'>
                Sahaspace is an engineering firm based in Thanjavur , Tamilnadu since 2020. we saha are expertise in construction | interior | landscape providing technical solutions for each clients based on their taste and style.
            </span>
            <span className='identify-content'>
                Sahaspace has emerged as a leading engineering firm with adequate skills in construction | interior | landscape and have been working across the key cities of Tamilnadu.
            </span>
            <span className='identify-content'>
                We guide our clients throughout the process by sharing our knowledge at each and every step till it best fits their needs.
            </span>
        </div>
        <div className='identity-second-div d-flex flex-column lh-lg'>
            <i className='identity-title'>Why <span className='fw-medium fst-normal'>SAHASPACE</span></i>
            <span className='identify-content'>
            Why not? Enchanted by our works, we ‘Sahaspace’ have been invited into our client’s moment of goodness. We believe the secret of our success is knowledge sharing, proper guidance and better interaction with our clients throughout the projects.
            </span>
            <span className='identify-content'>
            By upgrading ourselves every day along with the changing engineering world, Sahaspace is emerging as a benchmark for quality projects in the construction field. Whatever the project is and whoever our clients are, we ‘saha’ provides the same best effort to make their dream true.
            </span>
        </div>
    </div>
    </>
  )
}

export default Identity