import React, { useEffect, useState } from 'react';
import './Banner.css';
import Header from '../Header/Header';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { FaSearch } from 'react-icons/fa';
import backgroundImageUrl from "../../assets/Images/SAHASPACE-BUSINESS-WTSP.webp"
import { Padding } from '@mui/icons-material';
import logo from '../../assets/Logo/SAHA-SPACE.png'
// import { motion } from 'framer-motion'


const Banner = () => {
  // const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  // const transition = {duration: 0.1}

  // useEffect(() => {
  //   const handleResize = () => {
  //     setWindowHeight(window.innerHeight);
  //   };

  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  return (
    <div
      className='Banner'
      style={{
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundSize: 'cover',
        height: '100vh', 
        backgroundRepeat:'no-repeat',
        width:'100%',
        Padding:0,
        // opacity:0.6,
      }}
    >
      <Header />
      <div className='banner-logo w-100 h-100 d-flex justify-content-center align-items-center'>
          <img src={logo} alt="logo" className='logo img-fluid'/>
      </div>
    </div>
  );
};

export default Banner;
