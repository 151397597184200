import React, { useEffect, useState } from 'react';
import './Navabr.css';
import LanguageIcon from '@mui/icons-material/Language';
import MenuIcon from '../../assets/Icons/menu.png';
import sahaLogo from '../../assets/Logo/1694089827591.png';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link, Navigate } from 'react-router-dom';

const Navbar = () => {
  const [menuBar, setMenuBar] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize',handleWidth)

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleWidth);
    };
  }, []);

  const handleScroll = () => {
    setScrollHeight(window.scrollY);
  };

  const getCurrentWidth = () => {
    return {
        width: window.innerWidth
    }
  }

  const handleWidth = () =>{
    setCurrentWidth(getCurrentWidth())
  }

  const [currentWidth,setCurrentWidth] = useState(getCurrentWidth())

  const handleContactClick = async() => {
    await setTimeout(()=>{
      currentWidth.width > 992? window.scrollTo(0, 2700) :
      currentWidth.width <= 992 && currentWidth.width > 768? window.scrollTo(0, 3200) :
      currentWidth.width <= 768 && currentWidth.width > 576? window.scrollTo(0, 4100) : 
      currentWidth.width <= 576? window.scrollTo(0, 4000) : window.scrollTo(0, 2700)
    },50)
    setMenuBar(false)
  };

  return (
    <>
      <div className=''>
        <div className="nav_bar_container py-3" 
        style={{backgroundColor: scrollHeight? 'rgba(0,0,0,0.7)':'transparent',animation:scrollHeight? 'slideDown 0.3s linear':'0.3s linear'}}
        >
          <div className="">
            <div className="nav_bar container d-flex justify-content-between align-items-center">
              <div className="nav_bar_img">
                <img src={sahaLogo} className='' alt="sahaspace_log" />
              </div>

              
              <ul className='nav_bar_ul  d-none d-md-flex gap-5 align-items-center'>
                <Link to={'/'} style={{color:'white'}}>
                  <li className="nav_bar_li">
                    <span className='h6 fw-light' style={{ cursor: 'pointer',margin:'0' }}>Home</span>
                  </li>
                </Link>
                <li className="nav_bar_li">
                  <Link to={'/about'} style={{color:"white"}}>
                    <span className='h6 fw-light' style={{ cursor: 'pointer' }}>
                      About us
                    </span>
                  </Link>
                </li>
                <li className="nav_bar_li">
                  <Link to={'/portfolio'} style={{color:"white"}}>
                    <span className='h6 fw-light' style={{ cursor: 'pointer' }}>
                      Portfolio
                    </span>
                  </Link>
                </li>
                <li className="nav_bar_li">
                <Link to={'/'} style={{color:"white"}} onClick={()=>handleContactClick()}>
                    <span className='h6 fw-light' style={{ cursor: 'pointer' }}>
                      Contact Us
                    </span>
                  </Link>
                </li>
              </ul>
              

              <div className='icon_shadow d-flex d-md-none' onClick={() => setMenuBar(!menuBar)}>
                <img src={MenuIcon} style={{ width: '25px' }} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer className='drawer' anchor="right" open={menuBar} onClose={() => setMenuBar(false)}
        PaperProps={{
          sx: {
            width: 240,
            alignItems:'center',
            justifyContent:"center",
            backgroundColor:"black"
          }
        }}
      >
        <List className='text-white'>
          <ListItem button>
            <Link to={'/'} style={{color:'white'}}>
              <ListItemText primary="Home" onClick={() => setMenuBar(!menuBar)} />
            </Link>
          </ListItem>
          <ListItem button>
            <Link to={'/about'} style={{color:'white'}}>
              <ListItemText primary="About us" onClick={() => setMenuBar(!menuBar)}/>
            </Link>
          </ListItem>
          <ListItem button>
            <Link to={'/portfolio'} style={{color:"white"}}>
              <ListItemText primary="Portfolio" onClick={() => setMenuBar(!menuBar)}/>
            </Link>
          </ListItem>
          <ListItem button>
          <Link to={'/'} style={{color:"white"}}>
              <ListItemText primary="Contact Us" onClick={() => handleContactClick()}/>
            </Link>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default Navbar;
