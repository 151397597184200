import React from 'react'
import './Aboutus.css'
import Aboutimage from '../../assets/Images/what_we_deliver.webp'
// import Aboutimage1 from '../../assets/Images/aboutimage2.webp'
// import Aboutimage2 from '../../assets/Images/aboutimage3.webp'

const  Aboutus = () => {
  return (
    <div className='about_us'> 
      <div class="container mt-5 mb-5">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 opt-sm-0 d-none d-sm-block">
            <div class="row align-items-center">
                <div class="col-lg-3">
                    <div class="row">
                        {/* <div class="col-lg-12 mt-4 pt-2">
                            <div class="card work-desk rounded border-0 shadow-lg overflow-hidden">
                                <img src={Aboutimage} class="img-fluid" alt="Image" />
                                <div class="img-overlay bg-dark"></div>
                            </div>
                        </div> */}
                    </div>
                </div>
                

                <div class="col-lg-8">
                    <div class="row">
                        <div class="col-lg-12 mt-4 pt-2">
                            <div class="card work-desk rounded border-0 shadow-lg overflow-hidden">
                                <img src={Aboutimage} class="img-fluid" alt="Image" />
                                <div class="img-overlay bg-dark"></div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="row ">
                        <div class="col-lg-12 col-md-12">
                            <div class="card work-desk rounded border-0 shadow-lg overflow-hidden">
                                <img src={Aboutimage1} class="img-fluid" alt="Image" />
                                <div class="img-overlay bg-dark"></div>
                            </div>
                        </div>
                        

                        <div class="col-lg-12 col-md-12 mt-4 pt-2">
                            <div class="card work-desk rounded border-0 shadow-lg overflow-hidden">
                                <img src={Aboutimage2} class="img-fluid" alt="Image" />
                                <div class="img-overlay bg-dark"></div>
                            </div>
                        </div>
                    </div> */}
                </div>
                
            </div>
            
        </div>
        <div class="col-lg-6 col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 opt-sm-0 ps-md-5">
            <span className='aboutus-title h2 fw-bold'>
                We deliver what we show
            </span>
            <div class="row align-items-center row-gap-3 pt-md-5 pt-sm-3 pt-3">
                <div class="col-lg-6 col-md-12 col-12">
                    <div class="row gap-3">
                        <div class="col-lg-12 col-md-12">
                            <div class="shadow-lg overflow-hidden">
                                <span className='aboutus-text fs-6'>
                                We, Saha, are a construction firm providing technical solutions that are minimal yet modern.
                                </span>
                            </div>
                        </div>
                        

                        <div class="col-lg-12 col-md-12">
                            <div class="shadow-lg overflow-hidden">
                                <span className='aboutus-text fs-6'>
                                A group of young and energetic team who are passionate about bringing the best out of every individual project.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-12">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="shadow-lg overflow-hidden">
                                <span className='aboutus-text fs-6'>
                                We strongly believe that every design has its own story. A good design is produced from a combination of technical knowledge, proportion, details, and scale, with the end product attaining its maximum potential in accordance with the concept.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>
        
    </div>
    
</div>
    </div>
  )
}
export default Aboutus;