import React, { useRef, useState } from 'react';
import './Service.css';
  import { Carousel } from 'react-responsive-carousel';
  import 'react-responsive-carousel/lib/styles/carousel.min.css';
import carousel_img1 from '../../assets/Images/landscape_img_1.webp'
import carousel_img2 from '../../assets/Images/exterior_img_3.webp'
import carousel_img3 from '../../assets/Images/carousel-interior.webp'
import carousel_img4 from '../../assets/Images/potrait_img_1.webp'
import carousel_img5 from '../../assets/Images/potrait_img_2.webp'
import carousel_img6 from '../../assets/Images/potrait_img_3.webp'
import { Link } from 'react-router-dom';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { duration } from '@mui/material';

const Service = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const windowWidth = useRef(window.innerWidth)

  const trans1 = {duration:0.7}
  const trans2 = {duration:0.8}
  const trans3 = {duration:0.9}

  const contents = [
    {
      img : windowWidth.current <= 567? carousel_img4 : carousel_img1,
      boldContent : "We believe that minimal is modern",
      NormalContent : "Minimalistic designs to satisfy the soul",
      animate : true
    },
    {
      img : windowWidth.current <= 567? carousel_img5 : carousel_img2,
      boldContent : "We makes space that speaks",
      NormalContent : "Even a small space into your favourite place!",
      animate : true
    },
    {
      img : windowWidth.current <= 567? carousel_img6 : carousel_img3,
      boldContent : "Renders are for real",
      NormalContent : "Quality renders that gives life to the visual",
      animate : true
    }
  ]
   
  return (
    <div className="services">
      <div className="carousel-container">
      <Carousel
        onChange={(index) => setCurrentSlide(index)}
        showThumbs={false} 
        showStatus={false}
        infiniteLoop={true} 
        centerMode={false}
        showArrows={true} 
        autoPlay={true}
        interval={5000}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50} 
        renderArrowNext={(clickHandler) => (
          <div
            className='position-absolute top-0 bottom-0 end-0 d-flex justify-content-center align-items-center'
            onClick={clickHandler}
          >
            <FaCaretRight className="fs-4 text-white" />
          </div>
        )}
        renderArrowPrev={(clickHandler) => (
            <div
              className='position-absolute top-0 bottom-0 start-0 d-flex align-items-center justify-content-center z-1'
              onClick={clickHandler}
            >
              <FaCaretLeft className="fs-4 text-white" />
            </div>
          )}
      >
        {contents.map((content, index) => (
          <div key={index} className="carousel-slide position-relative">
            <img src={content.img} alt='' className='opacity-50' />
            <div 
            className='carousel-inner-text m-0 p-0 position-absolute top-0 left-0 right-0 bottom-0 d-flex flex-column justify-content-center align-items-center px-3 z-2'
            >
              <motion.div
              initial={{transform: 'translateY(150px)',opacity: 0}}
              animate={currentSlide === index ? { transform: 'translateY(0)',opacity: 1 } : {}}
              transition={trans1}
              >
                <span className='carousel-inner-text-first fs-1 fw-light'>{content.boldContent}</span>
              </motion.div>
              <motion.div
              className='pb-3'
              initial={{transform: 'translateY(150px)',opacity: 0}}
              animate={currentSlide === index ? { transform: 'translateY(0)',opacity: 1 } : {}}
              transition={trans2}
              >
                <span className='carousel-inner-text-second fs-5 fw-light'>{content.NormalContent}</span>
              </motion.div>
              <motion.div
              initial={{transform: 'translateY(150px)',opacity: 0}}
              animate={currentSlide === index ? { transform: 'translateY(0)',opacity: 1 } : {}}
              transition={trans3}
              >
                <Link to={'portfolio'}>
                  <a className='carousel-inner-text-third btn btn-dark px-4 rounded-0' href=''>
                    View Portfolio
                  </a>
                </Link>
              </motion.div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
    </div>
    
    
  );
};

export default Service;
