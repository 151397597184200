import React from "react";
import "./Footer.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import whatsapp from "../../assets/Icons/whatsapp.png";
import {BiLogoFacebookCircle} from "react-icons/bi"
import {BsWhatsapp} from "react-icons/bs"
import {BsTwitter} from "react-icons/bs"
import { Link } from "react-router-dom";

const Footer = () => {
  const handleWhatsAppClick = () => {
    const whatsappNumber = "9363657832";
    window.open(`https://api.whatsapp.com/send?phone=${whatsappNumber}`, '_blank');
  };

  return (
    <div className="my-5">
      <footer className="text-center text-lg-start text-white footer" style={{ backgroundColor: "#45526e" }}>
        <Container className="p-4 pb-0">
          <section className="">
            <Row>
              <Col md={4} lg={3} mxAuto mt={3}>
                <h6 className="text-uppercase mb-4 fw-bold">Saha Space</h6>
                <p className="fw-light">
                  No. 145/1, Jayam Nagar, Gnanam Nagar, Mariamman Kovil Road, Thanjavur, Tamil Nadu - 613501
                </p>
              </Col>
              <hr className="w-100 clearfix d-md-none" />
              <Col md={4} lg={3} mxAuto mt={3} className="links fw-light">
                <h6 className="text-uppercase mb-4 fw-bold">Useful links</h6>
                <p>
                  <Link to={'/'} style={{color:"white"}}>
                    <a className="text-white ">Home</a>
                  </Link>
                </p>
                <p>
                  <Link to={'/about'} style={{color:"white"}}>
                    <a className="text-white ">About Us</a>
                  </Link>
                </p>
                <p>
                  <Link to={'/portfolio'} style={{color:"white"}}>
                    <a className="text-white ">Portfolio</a>
                  </Link>
                </p>
                <p>
                  {/* <Link to={'/'} style={{color:"white"}}> */}
                    <a className="text-white ">Contact Us</a>
                  {/* </Link> */}
                </p>
              </Col>
              <hr className="w-100 clearfix d-md-none" />
              <Col md={4} lg={3} mxAuto mt={3} className="">
              <h6 className="text-uppercase mb-4 fw-bold">Follow Us</h6>
                <a className="btn btn-outline-light btn-floating m-1" role="button"><BiLogoFacebookCircle /></a>
                <a className="btn btn-outline-light btn-floating m-1" onClick={handleWhatsAppClick} role="button"><BsWhatsapp /></a>
                <a className="btn btn-outline-light btn-floating m-1" role="button"><BsTwitter /></a>
              </Col>
              <hr className="w-100 clearfix d-md-none mt-3" />
              <Col md={4} lg={3} mxAuto mt={3}>
                <h6 className="text-uppercase mb-4 fw-bold">Contact</h6>
                <p className="fw-light"> Thanjavur</p>
                <p className="fw-light"> info@sahaspace.in</p>
                <p className="fw-light"> +91 93636 57832</p>
                <p className="fw-light"> +91 70106 07072</p>
              </Col>
            </Row>
          </section>
          {/* <hr className="my-3" /> */}
          {/* <section className="p-3 pt-0">
            <Row className="d-flex align-items-center">
              <Col md={7} lg={8} className="text-center text-md-start">
                <div className="p-3">
                  © 2020 Copyright: {' '}
                  <a className="text-white" href="#">Saha Space</a>
                </div>
              </Col>
              <Col md={4} lg={4} className="ml-lg-0 text-center text-md-end">
                <a className="btn btn-outline-light btn-floating m-1 rounded-circle" role="button"><BiLogoFacebookCircle /></a>
                <a className="btn btn-outline-light btn-floating m-1" onClick={handleWhatsAppClick} role="button"><BsWhatsapp /></a>
                <a className="btn btn-outline-light btn-floating m-1" role="button"><BsTwitter /></a>
              </Col>
            </Row>
          </section> */}
        </Container>
      </footer>
      <div className="d-md-none" style={{ position: "fixed", bottom: "20px", right: "20px", zIndex: "999" }}>
        <img
          className="social-icons"
          src={whatsapp}
          alt="whatsapp"
          onClick={handleWhatsAppClick}
          style={{ cursor: "pointer" }}
        />
      </div>
    </div>
  );
};

export default Footer;
