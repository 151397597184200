import React from 'react'
// import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => (
    <div style={{
      position: 'relative', color: 'white', background: 'red',
      height: 40, width: 60, top: -20, left: -30,
    }}>
      {text}
    </div>
  );

function Googlemap() {

    // const defaultProps = {
    //     center: {
    //       lat: 10.99835602,
    //       lng: 77.01502627
    //     },
    //     zoom: 11
    //   };

  return (
    <div>
        <div className=''>
          <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.398573534386!2d79.16869337550276!3d10.780754259112927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baab919ec1581e9%3A0x91273545b8af0e78!2sSahaspace!5e0!3m2!1sen!2sin!4v1694754109847!5m2!1sen!2sin" 
          style={{border:0,width:"100%",height:"50vh"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
            {/* <GoogleMapReact
                bootstrapURLKeys={{ key: "" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
            >
                <AnyReactComponent
                lat={59.955413}
                lng={30.337844}
                text="My Marker"
                />
            </GoogleMapReact> */}
        </div>
        <div className='container d-flex flex-sm-row flex-column align-items-center justify-content-sm-between text-secondary py-5 font-monospace'>
            <span className='pb-sm-0 pb-3'>© 2020 Copyright: Saha Space</span>
            <div className='d-flex flex-column gap-1'>
              <span>Website by Best Labz</span>
              <span>+91 77085 45098</span>
            </div>
        </div>
    </div>
  )
}

export default Googlemap