import React, { useEffect, useState } from 'react'
import './Portfolio.css'
import portfolioImg1 from '../../assets/Images/interior_img_1.webp'
import portfolioImg2 from '../../assets/Images/interior_img_2.webp'
import portfolioImg3 from '../../assets/Images/interior_img_3.webp'
import portfolioImg4 from '../../assets/Images/interior_img_4.webp'
import portfolioImg5 from '../../assets/Images/interior_img_5.webp'
import portfolioImg6 from '../../assets/Images/interior_img_6.webp'
import portfolioImg7 from '../../assets/Images/interior_img_7.webp'
import portfolioImg8 from '../../assets/Images/interior_img_8.webp'
import portfolioImg9 from '../../assets/Images/interior_img_9.webp'
import portfolioImg10 from '../../assets/Images/interior_img_10.webp'
import portfolioImg11 from '../../assets/Images/exterior_img_1.webp'
import portfolioImg12 from '../../assets/Images/exterior_img_2.webp'
import portfolioImg13 from '../../assets/Images/exterior_img_3.webp'
import portfolioImg14 from '../../assets/Images/exterior_img_4.webp'
import portfolioImg15 from '../../assets/Images/exterior_img_5.webp'
import portfolioImg16 from '../../assets/Images/exterior_img_6.webp'
import portfolioImg17 from '../../assets/Images/exterior_img_7.webp'
import portfolioImg18 from '../../assets/Images/exterior_img_8.webp'
import portfolioImg19 from '../../assets/Images/landscape_img_1.webp'
import portfolioImg20 from '../../assets/Images/landscape_img_2.webp'
import portfolioImg21 from '../../assets/Images/landscape_img_3.webp'
import portfolioImg22 from '../../assets/Images/landscape_img_4.webp'
import portfolioImg23 from '../../assets/Images/landscape_img_5.webp'
import portfolioImg24 from '../../assets/Images/landscape_img_6.webp'
// import portfolioImg25 from '../../assets/Images/landscape_img_7.webp'
// import portfolioImg26 from '../../assets/Images/landscape_img_8.webp'
import portfolioImg27 from '../../assets/Images/exterior_img_9.webp'
import portfolioImg28 from '../../assets/Images/carousel-interior.webp'
import portfolioImg29 from '../../assets/Images/interior_img_11.webp'
import portfolioImg30 from '../../assets/Images/interior_img_12.webp'
import portfolioImg31 from '../../assets/Images/interior_img_13.webp'
import portfolioImg32 from '../../assets/Images/interior_img_14.webp'
import portfolioImg33 from '../../assets/Images/interior_img_15.webp'
import portfolioImg34 from '../../assets/Images/interior_img_16.webp'
import portfolioImg35 from '../../assets/Images/interior_img_17.webp'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '../../assets/Icons/menu.png';
import { Link } from 'react-router-dom'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import Googlemap from '../GoogleMap/Googlemap'

function Portfolio() {

    const options = ["All","Interior","Exterior","Landscape"]

    useEffect(()=>{
        window.scrollTo(0,0)
    })

    const portfolioImages = [
        {
            img : portfolioImg1,
            type :"Interior"
        },
        {
            img : portfolioImg2,
            type :"Interior"
        },
        {
            img : portfolioImg3,
            type :"Interior"
        },
        {
            img : portfolioImg4,
            type :"Interior"
        },
        {
            img : portfolioImg5,
            type :"Interior"
        },
        {
            img : portfolioImg6,
            type :"Interior"
        },
        {
            img : portfolioImg7,
            type :"Interior"
        },
        {
            img : portfolioImg8,
            type :"Interior"
        },
        {
            img : portfolioImg9,
            type :"Interior"
        },
        {
            img : portfolioImg10,
            type :"Interior"
        },
        {
            img : portfolioImg11,
            type :"Exterior"
        },
        {
            img : portfolioImg12,
            type :"Exterior"
        },
        {
            img : portfolioImg13,
            type :"Exterior"
        },
        {
            img : portfolioImg35,
            type :"Interior"
        },
        {
            img : portfolioImg14,
            type :"Exterior"
        },
        {
            img : portfolioImg15,
            type :"Exterior"
        },
        {
            img : portfolioImg16,
            type :"Exterior"
        },
        {
            img : portfolioImg17,
            type :"Exterior"
        },
        {
            img : portfolioImg18,
            type :"Exterior"
        },
        {
            img : portfolioImg19,
            type :"Interior"
        },
        {
            img : portfolioImg20,
            type :"Landscape"
        },
        {
            img : portfolioImg21,
            type :"Landscape"
        },
        {
            img : portfolioImg22,
            type :"Landscape"
        },
        {
            img : portfolioImg23,
            type :"Landscape"
        },
        {
            img : portfolioImg24,
            type :"Landscape"
        },
        {
            img : portfolioImg27,
            type :"Exterior"
        },
        {
            img : portfolioImg28,
            type :"Interior"
        },
        {
            img : portfolioImg29,
            type :"Interior"
        },
        {
            img : portfolioImg30,
            type :"Interior"
        },
        {
            img : portfolioImg31,
            type :"Interior"
        },
        {
            img : portfolioImg32,
            type :"Interior"
        },
        {
            img : portfolioImg33,
            type :"Interior"
        },
        {
            img : portfolioImg34,
            type :"Interior"
        },
    ]

    const [menuBar, setMenuBar] = useState(false);
    const [type,isType] = useState("All")
    const [selected,isSelected] = useState(portfolioImages)

    const handleFilter = (option) =>{
        isType(option)
        if(option !== "All"){
            isSelected(portfolioImages.filter(item => item.type === option))
        }
        else{
            isSelected(portfolioImages)
        }
    }

  return (
    <div className='bg-black text-white' style={{minHeight:"100vh"}}>
        <Navbar/>
        <div className='container' style={{paddingTop:'6rem',paddingBottom:'6rem'}}>
            <span className='h1 fw-light'>Portfolio</span>
            <div className='w-100 d-flex justify-content-center py-5'>
                {
                    options.map((option,i)=>(
                        <span href='' className='pe-3 fw-light' key={i} style={{color:option === type? "orangered":"white",cursor:"pointer"}}  onClick={() => handleFilter(option)}>{option}</span>
                    ))
                }
            </div>
            <div>
                <ResponsiveMasonry columnsCountBreakPoints={{350:1, 750:2, 900:3}}>
                    <Masonry 
                    gutter='15px'
                    autoArrange = {true}
                    >
                        {
                            selected.map((item,i)=>{
                                return(
                                    <img 
                                    className='portfolio-img' 
                                    src={item.img} key={i} 
                                    style={{width:"100%"}} 
                                    alt=""
                                    />
                                )
                            })
                        } 
                    </Masonry>
                </ResponsiveMasonry>
            </div>
        </div>
        <Footer/>
        <Googlemap/>
    </div>
  )
}

export default Portfolio